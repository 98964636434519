// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11233(0d00307b65)-C28/01/2025-12:18:07-B28/01/2025-12:57:23' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11233(0d00307b65)-C28/01/2025-12:18:07-B28/01/2025-12:57:23",
  branch: "master",
  latestTag: "6.1",
  revCount: "11233",
  shortHash: "0d00307b65",
  longHash: "0d00307b65b033d12d9ddd721a1fd4aa5884bf67",
  dateCommit: "28/01/2025-12:18:07",
  dateBuild: "28/01/2025-12:57:23",
  buildType: "Ansible",
  } ;
